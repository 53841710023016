import ProjectConfiguration from "../configuration/project";
import {AuthenticationResponse, LoginCredentials} from "./types";

/**
 * Authentication
 *
 *  - By default, all data in the system is off limits for unauthenticated users.
 *  - To gain access to protected data, you must include an access token with every request, or configure permissions for the public role.
 */

/**
 * Retrieve a Temporary Access Token
 * @param loginCredentials
 */
export async function Login(
  loginCredentials: LoginCredentials
): Promise<AuthenticationResponse> {
  let authenticationResponse: AuthenticationResponse =
    {} as AuthenticationResponse;

  // if (
  //   isEmpty(loginCredentials) ||
  //   isNull(loginCredentials || isUndefined(loginCredentials))
  // ) {
  //   loginCredentials = {
  //     email: ProjectConfiguration.DIRECTUS_MANAGEMENT_EMAIL,
  //     password: ProjectConfiguration.DIRECTUS_MANAGEMENT_PASSWORD,
  //   };
  // }

  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(loginCredentials),
  };

  await fetch(
    ProjectConfiguration.DIRECTUS_BASE_URL + "/auth/login",
    requestOptions
  )
    .then(async (response) => {
      const data = await response.json();

      if (!response.ok) {
        // Get Error Message from Body or Default to Response Status
        const error = (data && data.errors) || response.status;
        // Return throwing Error obtained
        return Promise.reject(error);
      }

      // console.log(
      //   ProjectConfiguration.CONSOLE_TAG.DIRECTUS,
      //   `Login Successful Of => ${loginCredentials?.email} => ${JSON.stringify(
      //     data.data
      //   )}`
      // );

      authenticationResponse = data.data;
    })
    .catch((error) => {
      console.error(error[0].extensions.code + ": " + error[0].message);
    });

  return authenticationResponse;
}

/**
 * Refresh a Temporary Access Token
 * @param refresh_token
 */
export async function Refresh(
  refresh_token: string
): Promise<AuthenticationResponse> {
  let authenticationResponse: AuthenticationResponse =
    {} as AuthenticationResponse;

  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(refresh_token),
  };

  await fetch(
    ProjectConfiguration.DIRECTUS_BASE_URL + "/auth/refresh",
    requestOptions
  )
    .then(async (response) => {
      const data = await response.json();

      if (!response.ok) {
        // Get Error Message from Body or Default to Response Status
        const error = (data && data.errors) || response.status;
        // Return throwing Error obtained
        return Promise.reject(error);
      }

      // console.log(
      //   ProjectConfiguration.CONSOLE_TAG.DIRECTUS,
      //   `Refresh Token => Performed => ${JSON.stringify(data.data)}`
      // );

      authenticationResponse = data.data;
    })
    .catch((error) => {
      console.error(error[0].extensions.code + ": " + error[0].message);
    });

  return authenticationResponse;
}

/**
 * Logout
 * @param refresh_token
 */
export async function Logout(refresh_token: string) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(refresh_token),
  };

  fetch(ProjectConfiguration.DIRECTUS_BASE_URL + "/auth/logout", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      if (!response.ok) {
        // Get Error Message from Body or Default to Response Status
        const error = (data && data.errors) || response.status;
        // Return throwing Error obtained
        return Promise.reject(error);
      }

      // console.log(
      //   ProjectConfiguration.CONSOLE_TAG.DIRECTUS,
      //   "Logout => Performed"
      // );
    })
    .catch((error) => {
      console.error(error);
    });
}

/**
 * Request a reset password email to be send
 * @param email
 */
export async function PasswordResetRequest(email: string) {
  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(email),
  };

  await fetch(
    ProjectConfiguration.DIRECTUS_BASE_URL + "/auth/password/request",
    requestOptions
  )
    .then(async (response) => {
      const data = await response.json();

      if (!response.ok) {
        // Get Error Message from Body or Default to Response Status
        const error = (data && data.errors) || response.status;
        // Return throwing Error obtained
        return Promise.reject(error);
      }

      // console.log(
      //   ProjectConfiguration.CONSOLE_TAG.DIRECTUS,
      //   `Request A Password Reset By => ${email}`
      // );
    })
    .catch((error) => {
      console.error(error[0].extensions.code + ": " + error[0].message);
    });

  return;
}
