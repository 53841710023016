import { useState, useEffect, useRef } from "react";
import { CommonStore } from "../stores/common";
import AudioPlayer from "../components/AudioPlayer";
import HeaderInfoPage from "../components/HeaderInfoPage";
import {
  IWindowDimensions,
  getWindowDimensions,
  isTablet,
  isPc,
} from "../utilities/utils";
import { Transition, CSSTransition } from "react-transition-group";
import {
  ComponentTransition,
  AnimationTypes,
} from "react-component-transition";
import "../styles/PointTransitions.css";
import useScrollPointInfo from "../hooks/useScrollPointInfo";
import { useSnackbar } from "notistack";
import ReactHtmlParser from "react-html-parser";
import Poems from "../components/Poems";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import ProjectConfiguration from "../configuration/project";
import { isVideo } from "../utilities/utils";
import ReactPlayer from "react-player";
import previous from "../assets/images/icons/previous.svg";
import next from "../assets/images/icons/next.svg";
import Cookies from "js-cookie";
import { motion } from "framer-motion";
import React from "react";
import { isDesktop } from "react-device-detect";
import useWindowOrientation from "use-window-orientation";
import Lottie from "react-lottie-player";
import loadingAnimation from "../assets/animations/loading.json";
import store from "../stores/common";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import logo from "../assets/images/logoSpinner.svg";

const PointInfo = () => {
  const { trackPageView, trackEvent } = useMatomo();
  const headerElement = useRef<HTMLDivElement>(null);
  const [image, setImage] = useState<number>(0);
  const { scroll } = useScrollPointInfo(headerElement);
  const [cssClass, setCssClass] = useState<String>("");
  const [showTransition, setShow] = useState<boolean>(true);
  const pointSelected = CommonStore((state) => state.pointSelected);
  const setNextPoint = CommonStore((state) => state.setNextPoint);
  const setPreviousPoint = CommonStore((state) => state.setPreviousPoint);
  const showInfoPage = CommonStore((state) => state.showInfoPage);
  const showPointsList = CommonStore((state) => state.showPointsList);
  const showMap = CommonStore((state) => state.showMap);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>(
    getWindowDimensions()
  );
  const { /*orientation, portrait,*/ landscape } = useWindowOrientation();
  const currentSurveyNot = CommonStore((state) => state.currentSurveyNot);
  const generalTexts = CommonStore((state) => state.generalTexts);
  const setCurrentMedia = CommonStore((state) => state.setCurrentMedia);
  const currentMedia = CommonStore((state) => state.currentMedia);
  const surveyDone = CommonStore((state) => state.surveyDone);
  const setToggleSendGAInfo = CommonStore((state) => state.setToggleSendGAInfo);
  const toggleSendGAInfo = CommonStore((state) => state.toggleSendGAInfo);
  const currentLang = CommonStore((state) => state.currentLang);
  const [readMore, setReadMore] = useState(true);

  function scrollToTop(scrollDuration: number) {
    //console.log(window.scrollY);
    var scrollStep = -window.scrollY / (scrollDuration / 15);
    var scrollInterval = setInterval(function () {
      if (window.scrollY != 0) {
        window.scrollBy(0, scrollStep);
      } else clearInterval(scrollInterval);
    }, 15);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setReadMore(true);
    setTimeout(() => {
      setWindowDimensions(getWindowDimensions());
    }, 100);
  }, [landscape]);

  useEffect(() => {
    closeSnackbar();
  }, [showPointsList, showMap]);

  useEffect(() => {
    if (currentSurveyNot !== undefined) {
      var index = currentSurveyNot.point_of_exposition.findIndex(
        (element) => pointSelected.id === element.point_of_exposition_id
      );

      if (firstUpdate.current) {
        firstUpdate.current = false;
        if (
          index !== -1 &&
          (Cookies.get("cookie_survey") == "false" ||
            Cookies.get("cookie_survey") == undefined) &&
          !surveyDone
        ) {
          setTimeout(() => {
            enqueueSnackbar("survey", {
              persist: true,
            });
          }, currentSurveyNot.point_of_exposition_time * 1000);
        }
      }
    }

    setReadMore(true);
  }, [pointSelected]);

  const changePoint = (action: string) => {
    setPoingChanging(!pointChanging);
    setTimeout(() => {
      window.scrollTo(0, 0);
      setCssClass(action === "next" ? "pointContainer" : "pointContainerOut");
      setShow(false);
      if (image === 0) {
        setImage(1);
      } else {
        setImage(0);
      }

      setTimeout(() => {
        if (action === "next") {
          setNextPoint();
        } else {
          setPreviousPoint();
        }

        setShow(true);
      }, 0);
    }, 350);
    setTimeout(() => {
      setPoingChanging(false);
    }, 800);
  };

  const [pointChanging, setPoingChanging] = useState(false);

  useEffect(() => {
    // GoogleAnalyticsUtilities.RecordUserInteractionEvent({
    //   category: ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.AREA,
    //   label: pointSelected.title,
    // });
    // trackEvent({
    //   category: ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.AREA,
    //   action: "event",
    //   name: pointSelected.title,
    // });
    trackPageView({
      documentTitle: "Página " + pointSelected.identifier, // optional
    });
    setCurrentMedia(-1);
  }, [pointSelected]);

  const ref = useRef<ReactPlayer | null>();

  const firstUpdate = useRef(true);

  const [visibility, setVisibility] = useState(true);

  const setCurrentNotification = CommonStore(
    (state) => state.setCurrentNotification
  );

  useEffect(() => {
    if (showMap !== 0) {
      setTimeout(() => {
        setVisibility(false);
      }, 500);
    } else {
      setVisibility(true);
    }
  }, [showMap]);

  useEffect(() => {
    if (
      isVideo(pointSelected.audio.blobFile.type) &&
      ref.current?.getCurrentTime() !== 0 &&
      ref.current?.getCurrentTime() !== null &&
      ref.current?.getCurrentTime() !== undefined
    ) {
      var time = ref.current?.getCurrentTime();
      var duration = ref.current?.getDuration();
      if (time !== undefined && duration !== undefined) {
        // GoogleAnalyticsUtilities.RecordUserInteractionEvent({
        //   category: ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.MULTIMEDIA,
        //   action: pointSelected.poems[i].title,
        //   label: Math.round(time).toString(),
        // });
        trackEvent({
          category:
            ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.MULTIMEDIASECONDS,
          action: currentLang.code,
          name: pointSelected.audio.identifier,
          value: Math.round(time),
        });
        trackEvent({
          category:
            ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.MULTIMEDIAPERCENTAGE,
          action: currentLang.code,
          name: pointSelected.audio.identifier,
          value: Math.round((time / duration) * 100),
        });
      }
    }
  }, [toggleSendGAInfo]);

  const [buttonLeftAnim, setButtonLeftAnim] = useState(false);
  const [buttonRightAnim, setButtonRightAnim] = useState(false);

  useEffect(() => {
    if (buttonLeftAnim) {
      setTimeout(() => {
        setButtonLeftAnim(false);
      }, 500);
    }
  }, [buttonLeftAnim]);

  useEffect(() => {
    if (buttonRightAnim) {
      setTimeout(() => {
        setButtonRightAnim(false);
      }, 500);
    }
  }, [buttonRightAnim]);

  return visibility ? (
    !isVideo(pointSelected.audio.blobFile.type) ? (
      <div
        className={`bg-dark-blue mb-16 h-full ${
          pointChanging ? "fade-pulse" : ""
        }`}
        // style={{
        //   width: windowDimensions.width,
        //   minHeight: windowDimensions.height,
        // }}
      >
        <Transition
          in={showTransition}
          timeout={500}
          appear
          unmountOnExit={true}
          //onExit={() => console.log("Destroy point info")}
        >
          {(status) => {
            return (
              <div>
                <div
                  ref={headerElement}
                  className={`fixed top-0 w-full bg-dark-blue z-50 ${
                    scroll! >= 100 ? "scrolled-header" : ""
                  }`}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.3,
                    }}
                  >
                    <HeaderInfoPage
                      scrolled={scroll! >= 100}
                      styles={{
                        height: !scroll
                          ? (windowDimensions.width * 2) / 3
                          : (windowDimensions.width * 2) / 3 - scroll,

                        minHeight: isDesktop ? 200 : 80,
                      }}
                    />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.1,
                    }}
                    className="w-full flex flex-col justify-center items-center bg-dark-blue mt-4 px-6 lg:pb-12"
                  >
                    <div
                      className={`w-full sm:w-11/12 lg:w-1/2 `}
                      style={{ zIndex: 300 }}
                    >
                      <AudioPlayer
                        id={0}
                        name={pointSelected.audio.identifier}
                        mainAudio={true}
                        changePoint={changePoint}
                        audioFile={pointSelected.audio}
                        scrolled={false}
                      />
                    </div>
                  </motion.div>
                </div>
                {(!landscape || isDesktop) && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.5,
                    }}
                    className="w-full flex flex-col justify-center items-center"
                    style={{
                      marginTop: (windowDimensions.width * 2) / 3 + 140,
                    }}
                  >
                    <div className="flex justify-center flex-col mt-2 w-full sm:w-11/12 lg:w-1/2">
                      {pointSelected.information !== "" && (
                        <div className="flex justify-start text-white px-8 mb-12 sm:mb-20 mt-8 sm:mt-14">
                          <div
                            className={`flex flex-col justify-center items-center relative ${
                              readMore ? "fade-in" : ""
                            }`}
                          >
                            <div className={`${readMore ? "box h-28" : ""}`}>
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: pointSelected.information,
                                  }}
                                ></span>
                              </p>
                            </div>

                            {readMore ? (
                              <button
                                className="absolute bottom-0 readMoreButton -mb-1"
                                onClick={() => {
                                  setReadMore(!readMore);
                                  trackEvent({
                                    category:
                                      ProjectConfiguration
                                        .GOOGLE_ANALYTICS_CATEGORY.READMORE,
                                    action: currentLang.code,
                                    name: pointSelected.identifier,
                                    value: 20,
                                  });
                                }}
                              >
                                {generalTexts?.read_more}
                              </button>
                            ) : (
                              <button
                                className="readMoreButton mt-4"
                                onClick={() => {
                                  setReadMore(!readMore);
                                  window.scrollTo(0, 0);
                                  // scrollToTop(600);
                                  // setTimeout(() => {
                                  //   setReadMore(!readMore);
                                  // }, 600);
                                }}
                              >
                                {generalTexts?.read_less}
                              </button>
                            )}
                          </div>
                        </div>
                      )}{" "}
                      {pointSelected.poems.length !== 0 && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{
                            duration: 0.5,
                            delay: 0,
                          }}
                          style={{ zIndex: 0 }}
                        >
                          <div className="px-8">
                            <h2 className="text-white text-2xl mb-10 sm:mb-14">
                              {generalTexts?.poems}
                            </h2>
                          </div>
                          <Poems />
                        </motion.div>
                      )}
                      {pointSelected.listPoems.length > 0 &&
                        pointSelected.poems.length === 0 && (
                          <div className="text-white w-full flex flex-col items-center mt-4">
                            <h2
                              className="text-white text-2xl"
                              style={{ opacity: 0.2 }}
                            >
                              {generalTexts?.poems}
                            </h2>
                            {/* <Lottie
                              animationData={loadingAnimation}
                              play
                              style={{ width: 75 }}
                            /> */}
                            <img className="spining" src={logo} width={30} />
                          </div>
                        )}
                    </div>
                  </motion.div>
                )}
              </div>
            );
          }}
        </Transition>{" "}
      </div>
    ) : (
      <div
        className={`bg-dark-blue mb-16 h-full ${
          pointChanging ? "fade-pulse" : ""
        }`}
        // style={{
        //   width: windowDimensions.width,
        //   minHeight: windowDimensions.height,
        // }}
      >
        <Transition
          in={showTransition}
          timeout={500}
          appear
          unmountOnExit={true}
          //onExit={() => console.log("Destroy point info")}
        >
          {(status) => {
            return (
              <div>
                <div
                  ref={headerElement}
                  className={`fixed top-0 w-full bg-dark-blue z-50 ${
                    scroll! >= 100 ? "scrolled-header" : ""
                  }`}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.3,
                    }}
                  >
                    <HeaderInfoPage
                      scrolled={scroll! >= 100}
                      styles={{
                        height: isTablet()
                          ? !scroll
                            ? (windowDimensions.width * 2) / 3 - 200
                            : (windowDimensions.width * 2) / 3 - 200 - scroll
                          : !scroll
                          ? (windowDimensions.width * 2) / 3
                          : (windowDimensions.width * 2) / 3 - scroll,

                        minHeight: isDesktop ? 200 : 80,
                      }}
                    />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.1,
                    }}
                    className="w-full flex flex-col justify-center items-center bg-dark-blue mt-0 px-20 sm:px-52 lg:pb-12"
                  >
                    <div
                      className={`w-full sm:w-10/12 lg:w-1/6`}
                      style={{ zIndex: 300 }}
                    >
                      <>
                        <ReactPlayer
                          playsinline
                          controls
                          ref={(el) => (ref.current = el)}
                          url={pointSelected.audio.file}
                          className="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered mb-8"
                          width="100%"
                          height="100%"
                          style={{}}
                          playing={currentMedia === 0}
                          onPlay={() => {
                            setCurrentMedia(0);
                          }}
                          onEnded={() => {
                            var time = ref.current?.getDuration();
                            if (time !== undefined) {
                              // GoogleAnalyticsUtilities.RecordUserInteractionEvent({
                              //   category: ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.MULTIMEDIA,
                              //   action: pointSelected.poems[i].title,
                              //   label: Math.round(time).toString(),
                              // });
                              trackEvent({
                                category:
                                  ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
                                    .MULTIMEDIASECONDS,
                                action: currentLang.code,
                                name: pointSelected.audio.identifier,
                                value: Math.round(time),
                              });
                              trackEvent({
                                category:
                                  ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
                                    .MULTIMEDIAPERCENTAGE,
                                action: currentLang.code,
                                name: pointSelected.audio.identifier,
                                value: 100,
                              });
                              ref.current?.seekTo(0);
                              setCurrentMedia(-1);
                            }

                            if (
                              pointSelected.audio.content_notification.time !==
                              -1
                            ) {
                              setTimeout(() => {
                                setCurrentNotification(pointSelected.audio);
                                enqueueSnackbar("content");
                              }, pointSelected.audio.content_notification.time * 1000);
                            }
                          }}
                        />

                        <div className="flex justify-around items-center mb-6 lg:mb-0">
                          <div
                            className={`cursor-pointer ${
                              buttonLeftAnim ? "buttonClicked" : ""
                            }`}
                          >
                            <img
                              width="25"
                              onClick={() => {
                                setButtonLeftAnim(true);
                                setToggleSendGAInfo(!toggleSendGAInfo);
                                changePoint("previous");
                              }}
                              src={previous}
                            />
                          </div>

                          <div
                            className={`cursor-pointer ${
                              buttonRightAnim ? "buttonClicked" : ""
                            }`}
                          >
                            <img
                              width="25"
                              onClick={() => {
                                setButtonRightAnim(true);
                                setToggleSendGAInfo(!toggleSendGAInfo);
                                changePoint("next");
                              }}
                              src={next}
                            />
                          </div>
                        </div>
                      </>
                    </div>
                  </motion.div>
                </div>
                {(!landscape || isDesktop) && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.5,
                    }}
                    className="w-full flex flex-col justify-center items-center "
                    style={{
                      marginTop: isTablet()
                        ? (windowDimensions.width * 2) / 3 + 440
                        : isPc()
                        ? (windowDimensions.width * 2) / 3 + 550
                        : (windowDimensions.width * 2) / 3 + 500,
                    }}
                  >
                    <div className="flex justify-center flex-col mt-2 w-full sm:w-11/12 lg:w-1/2">
                      {pointSelected.information !== "" && (
                        <div className="flex justify-start text-white px-8 mb-12 sm:mb-20 mt-6 sm:mt-14">
                          <div
                            className={`flex flex-col justify-center items-center relative ${
                              readMore ? "fade-in" : ""
                            }`}
                          >
                            <div className={`${readMore ? "box h-28" : ""}`}>
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: pointSelected.information,
                                  }}
                                ></span>
                              </p>
                            </div>

                            {readMore ? (
                              <button
                                className="absolute bottom-0 readMoreButton -mb-1"
                                onClick={() => {
                                  setReadMore(!readMore);
                                  trackEvent({
                                    category:
                                      ProjectConfiguration
                                        .GOOGLE_ANALYTICS_CATEGORY.READMORE,
                                    action: currentLang.code,
                                    name: pointSelected.identifier,
                                    value: 20,
                                  });
                                }}
                              >
                                {generalTexts?.read_more}
                              </button>
                            ) : (
                              <button
                                className="readMoreButton mt-4"
                                onClick={() => {
                                  setReadMore(!readMore);
                                  window.scrollTo(0, 0);
                                  // scrollToTop(600);
                                  // setTimeout(() => {
                                  //   setReadMore(!readMore);
                                  // }, 600);
                                }}
                              >
                                {generalTexts?.read_less}
                              </button>
                            )}
                          </div>
                        </div>
                      )}{" "}
                      {pointSelected.poems.length !== 0 && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{
                            duration: 0.5,
                            delay: 0,
                          }}
                          style={{ zIndex: 0 }}
                        >
                          <div className="px-8">
                            <h2 className="text-white text-2xl mb-10 sm:mb-14">
                              {generalTexts?.poems}
                            </h2>
                          </div>
                          <Poems />
                        </motion.div>
                      )}
                      {pointSelected.listPoems.length > 0 &&
                        pointSelected.poems.length === 0 && (
                          <div className="text-white w-full flex flex-col items-center mt-4">
                            <h2
                              className="text-white text-2xl"
                              style={{ opacity: 0.2 }}
                            >
                              {generalTexts?.poems}
                            </h2>
                            {/* <Lottie
                              animationData={loadingAnimation}
                              play
                              style={{ width: 75 }}
                            /> */}
                            <img className="spining" src={logo} width={30} />
                          </div>
                        )}
                    </div>
                  </motion.div>
                )}
              </div>
            );
          }}
        </Transition>{" "}
      </div>
    )
  ) : (
    <></>
  );
};

export default PointInfo;
