import * as React from "react";
import { SVGProps } from "react";
import { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import CommonStore from "../stores/common";
import { isChrome, isIOS } from "react-device-detect";
import { useSnackbar } from "notistack";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import ProjectConfiguration from "../configuration/project";

interface hotspotProps {
  top: string;
  left: string;
  name: string;
  numberName: number;
}

const Hotspot = ({ top, left, name, numberName }: hotspotProps) => {
  const setHotspotClicked = CommonStore(
    (state: any) => state.setHotspotClicked
  );
  const hotspotClicked = CommonStore((state: any) => state.hotspotClicked);
  const [avoidAnimation, setAvoidAnimation] = useState(true);
  const currentPoints = CommonStore((state) => state.currentPoints);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (hotspotClicked === name) {
      setAvoidAnimation(false);
    }
  }, [hotspotClicked]);

  return currentPoints !== undefined ? (
    <div
      style={{
        position: "absolute",
        top: top,
        left: left,
        transform: hotspotClicked === name ? "scale(0.08)" : "scale(0.06)",
        width: "100%",
        height: "100%",
        zIndex: 100,
      }}
      className={
        isIOS
          ? ""
          : hotspotClicked === name
          ? "bigHotspot"
          : avoidAnimation
          ? " "
          : "smallHotspot"
      }
      onClick={() => {
        setHotspotClicked(name);
      }}
    >
      {numberName > 0 ? (
        <div className="flex flex-col justify-center items-center">
          <div
            className="flex justify-center items-center"
            style={{
              fontSize: "20rem",
              width: "100%",
              lineHeight: "12rem",
              color: "#e5007d",
              fontWeight: "bold",
              marginBottom: "6rem",
            }}
          >
            {numberName.toString().padStart(2, "0")}
          </div>
          <div
            className="text-white flex justify-center items-center text-center mb-32"
            style={{
              fontSize: "15rem",
              width: "100%",
              lineHeight: "12rem",
            }}
          >
            {name}
          </div>
        </div>
      ) : (
        <div
          className="text-white flex justify-center items-center text-center mb-32"
          style={{
            fontSize: "15rem",
            width: "100%",
            lineHeight: "12rem",
            color: "#e5007d",
            fontWeight: "bold",
          }}
        >
          {name}
        </div>
      )}

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.27 104.2">
        <g data-name="Capa 2">
          <path
            d="M35.13 0A35.13 35.13 0 0 0 0 35.13c0 36.14 35.13 69.07 35.13 69.07s35.14-33.71 35.14-69.07A35.13 35.13 0 0 0 35.13 0Zm0 54.94a18.29 18.29 0 1 1 18.29-18.29 18.28 18.28 0 0 1-18.29 18.29Z"
            fill={hotspotClicked === name ? "white" : "#e4007d"}
            className={
              isIOS
                ? ""
                : hotspotClicked === name
                ? "goWhite"
                : avoidAnimation
                ? " "
                : "goRed"
            }
            data-name="Capa 1"
          />
        </g>
      </svg>
    </div>
  ) : (
    <></>
  );
};

export default Hotspot;
