import proximo from "../assets/images/icons/proximo.svg";
import Icon from "./SVGs/Charla";
import { motion } from "framer-motion";
import CommonStore from "../stores/common";

interface ActionRectangreProps {
  title: string;
  subtitle?: string;
  componentStyles: string;
  titleStyles: string;
  onClickAction: () => void;
}

const ActionRectange2 = ({
  title,
  subtitle,
  componentStyles,
  titleStyles,
  onClickAction,
}: ActionRectangreProps) => {
  const showMap = CommonStore((state: any) => state.showMap);
  const showLanguagesSelector = CommonStore(
    (state: any) => state.showLanguagesSelector
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
        delay: showMap ? 0.1 : 0.3,
      }}
      onClick={onClickAction}
      className={`${componentStyles} flex flex-row justify-center items-cente text-white my-4 px-4 cursor-pointer 
      }`}
      style={{
        backgroundColor: title === "Torre Glòries" ? "#820098" : "#0029de",
      }}
    >
      <div className={`${titleStyles} flex flex-col align-center`}>
        <span className={`${titleStyles} font-medium`}>{title}</span>
        <small className="text-base font-light">{subtitle}</small>
      </div>
      <div className="ml-auto flex items-center">
        <Icon />
      </div>
    </motion.div>
  );
};

export default ActionRectange2;
