import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import CommonStore from "../stores/common";
import MapPlantaBaja from "../components/MapPlantaBaja";
import MapCupula from "../components/MapCupula";
// import { salas } from "../services/api";
import ActionRectange from "../components/ActionRectangle";
import { ILang, IPointInfo } from "../utilities/CmsUtils";
import { useSnackbar } from "notistack";
import logo from "../assets/images/icons/TG_landsc.svg";
import { PuntoExpositivoData } from "../utilities/CmsUtils";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import ProjectConfiguration from "../configuration/project";
import CloseIcon from "@material-ui/icons/Close";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const Map = () => {
  const { trackPageView, trackEvent } = useMatomo();
  const lastPagePointInfo = CommonStore(
    (state: any) => state.lastPagePointInfo
  );

  const showMap = CommonStore((state: any) => state.showMap);
  const setShowMap = CommonStore((state: any) => state.setShowMap);
  const setHotspotClicked = CommonStore(
    (state: any) => state.setHotspotClicked
  );
  const setSelectedPoint = CommonStore((state) => state.setSelectedPoint);
  const hotspotClicked = CommonStore((state: any) => state.hotspotClicked);

  const [point, setPoint] = useState<PuntoExpositivoData>();

  function getSala(currentSala: PuntoExpositivoData) {
    return currentSala.title === hotspotClicked;
  }

  const currentPoints = CommonStore((state) => state.currentPoints);
  const allPointsInfo = CommonStore((state) => state.allPointsInfo);
  const setShowInfoPage = CommonStore((state: any) => state.setShowInfoPage);
  const showPointsList = CommonStore((state) => state.showPointsList);
  const showInfoPage = CommonStore((state) => state.showInfoPage);
  const setDontHideMap = CommonStore((state) => state.setDontHideMap);
  const setShowPointsList = CommonStore((state) => state.setShowPointsList);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function checkCurrentSala() {
    setPoint(currentPoints?.filter(getSala)[0]);
  }

  useEffect(() => {
    checkCurrentSala();
  }, [hotspotClicked]);

  function getSelectedPointIndex() {
    return currentPoints !== undefined
      ? currentPoints.findIndex((element) => element.title === hotspotClicked)
      : 0;
  }

  useEffect(() => {
    closeSnackbar();
  }, [showInfoPage, showPointsList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // <div>
    //   {" "}
    //   MAP
    //   <button
    //     className="block bg-red-500"
    //     onClick={() => {
    //       setSelectedPoint(0);
    //       setShowInfoPage(true);
    //     }}
    //   >
    //     BACK
    //   </button>
    // </div>
    <div>
      {hotspotClicked !== "" && showMap && (
        <div className="w-full flex justify-center">
          <div
            className="absolute bottom-0 w-full flex justify-center sm:justify-start"
            style={{ zIndex: 300 }}
          >
            <ActionRectange
              key={Math.random()}
              title={point !== undefined ? point.title : ""}
              subtitle={point !== undefined ? point.description : ""}
              numberName={point !== undefined ? point?.number_name : -1}
              componentStyles={
                "w-11/12 sm:w-1/2 py-3 px-6 mb-16 lg:mb-24 sm:mb-16 sm:ml-8 lg:w-1/3 gap-x-4"
              }
              onClickAction={() => {
                setShowMap(0);
                if (lastPagePointInfo === -1) {
                  setDontHideMap(true);
                } else {
                  setDontHideMap(false);
                }
                setSelectedPoint(getSelectedPointIndex());
                setHotspotClicked("");
                setShowInfoPage(true);
              }}
              titleStyles={"text-xl"}
            ></ActionRectange>
          </div>
        </div>
      )}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.2,
        }}
        className="absolute top-0 w-full pl-6 flex content-center text-white"
        style={{ height: "15%", backgroundColor: "#0900b9" }}
      >
        <img src={logo} width={180} />
        <div
          onClick={() => {
            setDontHideMap(false);
            if (lastPagePointInfo !== -1) {
              setSelectedPoint(lastPagePointInfo);
              setShowMap(0);
              if (currentPoints !== undefined) {
                trackPageView({
                  documentTitle:
                    "Página " + currentPoints[lastPagePointInfo].identifier, // optional
                });
              }
            } else {
              setShowMap(0);
              trackPageView({
                documentTitle: "Página Lista Puntos de Exposición", // optional
              });
            }
            setHotspotClicked("");
          }}
          className="absolute top-0 right-0 mr-4 mt-4 sm:mr-6 sm:mt-6 cursor-pointer lg:mr-12"
        >
          <CloseIcon />
        </div>
        {/* <button
          className="block bg-red-500"
          onClick={() => {
            setSelectedPoint(0);
            setShowInfoPage(true);
          }}
        >
          BACK
        </button> */}
      </motion.div>

      <div>{showMap === 1 ? <MapPlantaBaja /> : <MapCupula />}</div>
    </div>
  );
};

export default Map;
