import { useState, useEffect } from "react";
import proximo from "../assets/images/icons/proximo.svg";
import logo from "../assets/images/icons/TG_logo.svg";
import charla from "../assets/images/icons/charla.svg";
import survey from "../assets/images/icons/happy.svg";
import ubicacion from "../assets/images/icons/ubicacion.svg";
import Icon from "../components/SVGs/Charla";
import CommonStore from "../stores/common";
import { motion } from "framer-motion";

import { Transition } from "react-transition-group";
import ChangeLanguage from "../components/ChangeLanguage";
import Cookies from "js-cookie";
interface IHeaderProps {
  currentPage: string;
}

const Header = ({ currentPage }: IHeaderProps) => {
  const [showSelectorLangs, setShowSelectorLangs] = useState<boolean>(false);
  const currentLang = CommonStore((state: any) => state.currentLang);
  const setShowMap = CommonStore((state: any) => state.setShowMap);
  const setShowLanguagesSelector = CommonStore(
    (state: any) => state.setShowLanguagesSelector
  );
  const currentPoints = CommonStore((state) => state.currentPoints);
  const setShowInfoPage = CommonStore((state: any) => state.setShowInfoPage);
  const setShowSurvey = CommonStore((state: any) => state.setShowSurvey);
  const setShowPointsList = CommonStore(
    (state: any) => state.setShowPointsList
  );
  const setSurveyCompletedNot = CommonStore(
    (state) => state.setSurveyCompletedNot
  );
  const surveyDone = CommonStore((state) => state.surveyDone);
  const setLastPagePointInfo = CommonStore(
    (state) => state.setLastPagePointInfo
  );

  function showMap() {
    setLastPagePointInfo(-1);
    setShowMap(1);
  }

  return (
    <div
      className="py-8 px-6 sm:py-12 sm:px-12 w-full absolute top-0"
      style={{ height: 200, zIndex: 0 }}
    >
      <div className="float-left">
        {" "}
        <img src={logo} alt="" />
      </div>
      {currentPoints !== undefined && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.1,
            }}
            className={`float-right lg:mr-8 ${
              currentPage !== "languages" ? "visible" : "invisible"
            }`}
          >
            {" "}
            <div className="float-right">
              <img
                onClick={() => showMap()}
                className="float-left cursor-pointer mr-3 sm:mr-5"
                src={ubicacion}
                alt=""
              />

              {(Cookies.get("cookie_survey") == "false" ||
                Cookies.get("cookie_survey") == undefined) &&
                !surveyDone && (
                  <img
                    onClick={() => {
                      setShowSurvey(true);
                      setSurveyCompletedNot(false);
                      setShowInfoPage(false);
                      setShowPointsList(false);
                      setShowMap(false);
                    }}
                    className="float-left cursor-pointer mr-4 sm:mr-5"
                    style={{ width: "1.85rem" }}
                    src={survey}
                    alt=""
                  />
                )}

              <button
                onClick={() => {
                  setShowMap(0);
                  setShowLanguagesSelector(true);
                }}
                className="text-white border-white px-2 uppercase"
                style={{
                  border: "1px solid white",
                  padding: "2px 8px 2px 8px",
                }}
              >
                {currentLang.short_name}
              </button>
            </div>
          </motion.div>

          <div
            className={`${
              currentPage === "languages" ? "visible" : "invisible"
            } pt-12 absolute bottom-0`}
          >
            <img src={charla} alt="" />
          </div>
        </>
      )}
    </div>

    // <div className="flex flex-col p-8">
    //   <div className="flex">
    //     <div>
    //       <img src={logo} alt="" />
    //     </div>
    //     {currentPage === "pointsList" && (
    //       <div className="flex ml-auto">
    //         <div>
    //           <img onClick={() => showMap()} src={ubicacion} alt="" />
    //         </div>
    //         <div>
    //           <button
    //             onClick={() => {
    //               setShowMap(0);
    //               setShowSelectorLangs(true);
    //               setShowLanguagesSelector(true);
    //             }}
    //             className="text-white border-2 border-white px-4 ml-4"
    //           >
    //             {currentLang.name}
    //           </button>
    //         </div>
    //       </div>
    //     )}
    //   </div>

    //   <div
    //     className={`${
    //       currentPage === "languages" ? "visible" : "invisible"
    //     } pt-12`}
    //   >
    //     <img src={charla} alt="" />
    //   </div>
    // </div>
  );
};

export default Header;
