import { useState, useEffect } from "react";

import Languages from "../pages/Languages";
import PointsList from "../pages/PointsList";
import PointInfo from "../pages/PointInfo";
import CustomSnackbar from "./CustomSnackbar";
import Map from "../pages/Map";
import ChangeLanguage from "./ChangeLanguage";
import Cookies from "js-cookie";
import { Transition } from "react-transition-group";
import { SnackbarProvider } from "notistack";
import CommonStore from "../stores/common";
import { IWindowDimensions, getWindowDimensions } from "../utilities/utils";
import "../App.css";
import "../styles/PointsListTransitions.css";
import "../styles/ChangeLanguageTransitions.css";
import Survey from "../pages/Survey";
import { useCookiesContext } from "../context/CookiesCtx";
import CookiesModal from "./CookiesModal";
import Footer from "./Footer";
import ProjectConfiguration from "../configuration/project";
// import ReactGA from "react-ga";
import Policy from "./Policy";
import {
  getGeneralTextsFromCMS,
  getLanguages,
  getLanguagesFromCMS,
  ILang,
} from "../utilities/CmsUtils";
import useWindowOrientation from "use-window-orientation";
import landscapeImg from "../assets/images/landscape.svg";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import { isDesktop, isMobile } from "react-device-detect";
import {
  MatomoProvider,
  createInstance,
  useMatomo,
} from "@datapunt/matomo-tracker-react";

interface TransitionStyles {
  entering: object;
  entered: object;
  exiting: object;
  exited: object;
}

type TransitionState = keyof TransitionStyles;

const MainElements = () => {
  const { /*orientation, portrait,*/ landscape } = useWindowOrientation();

  const { cookies, analyticsCookies, preferenceCookies } = useCookiesContext();
  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>(
    getWindowDimensions()
  );
  const [cssClassPointsList, setCssClassPointsList] =
    useState<string>("hidden");

  const setCurrentLang = CommonStore((state: any) => state.setCurrentLang);
  const setShowPointsList = CommonStore(
    (state: any) => state.setShowPointsList
  );
  const generalTexts = CommonStore((state: any) => state.generalTexts);
  const showPointsList = CommonStore((state: any) => state.showPointsList);
  const showPolicy = CommonStore((state: any) => state.showPolicy);

  const showLanguagesSelector = CommonStore(
    (state: any) => state.showLanguagesSelector
  );
  const setShowLanguagesSelector = CommonStore(
    (state: any) => state.setShowLanguagesSelector
  );

  const showLanguages = CommonStore((state: any) => state.showLanguages);
  const setShowLanguages = CommonStore((state: any) => state.setShowLanguages);
  const lastPagePointInfo = CommonStore(
    (state: any) => state.lastPagePointInfo
  );
  const showMap = CommonStore((state: any) => state.showMap);
  const setShowMap = CommonStore((state: any) => state.setShowMap);
  const showSurvey = CommonStore((state) => state.showSurvey);
  const setShowSurvey = CommonStore((state) => state.setShowSurvey);
  const showInfoPage = CommonStore((state: any) => state.showInfoPage);
  const setShowInfoPage = CommonStore((state: any) => state.setShowInfoPage);
  const setSelectedPoint = CommonStore((state) => state.setSelectedPoint);
  const languageSelected = CommonStore((state) => state.languageSelected);
  const pointSelected = CommonStore((state: any) => state.pointSelected);
  const intoPoint = CommonStore((state: any) => state.intoPoint);
  const setIntoPoint = CommonStore((state: any) => state.setIntoPoint);
  const dontHideMap = CommonStore((state: any) => state.dontHideMap);
  const setDontHideMap = CommonStore((state: any) => state.setDontHideMap);
  const pointInfoIndex = CommonStore((state: any) => state.pointInfoIndex);
  const setPointInfoIndex = CommonStore(
    (state: any) => state.setPointInfoIndex
  );
  const currentLang = CommonStore((state) => state.currentLang);
  const [firstTime, setFirstTime] = useState(true);
  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    if (cookies && currentLang.code !== undefined) {
      // ReactGA.initialize(ProjectConfiguration.REACT_GA_TRACKING_ID);
      // ReactGA.pageview("/");
      trackEvent({
        category: ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.LANGUAGE,
        action: "selected",
        name: currentLang.code,
        value: 20,
      });

      if (firstTime) {
        trackPageView({
          documentTitle: "Página Lista Puntos de Exposición", // optional
        });
        if (languageSelected) {
          trackPageView({
            documentTitle: "Página Seleccionar Idioma", // optional
          });
        }
        setFirstTime(false);
      }
    }
  }, [currentLang]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="App">
      <SnackbarProvider
        content={(key, message) => (
          <CustomSnackbar id={key} message={message} />
        )}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        maxSnack={1}
      >
        {showLanguages && (!landscape || isDesktop) && <Languages />}

        <Transition
          in={showPointsList && (!landscape || isDesktop)}
          timeout={500}
          appear
          unmountOnExit={true}
          onEntered={() => {
            setCssClassPointsList("on-screen");
            setShowLanguages(false);
          }}
        >
          {(status: TransitionState) => {
            return (
              <div
                className={`pointsList pointsList-${cssClassPointsList}-${status} bg-dark-blue-menu`}
                style={{
                  width: windowDimensions.width,
                  minHeight: windowDimensions.height,
                }}
              >
                <PointsList />
              </div>
            );
          }}
        </Transition>

        <Transition
          in={showInfoPage}
          timeout={500}
          appear
          unmountOnExit={true}
          onEnter={() => {
            setShowPointsList(false);
            setShowMap(0);
            setPointInfoIndex(true);
          }}
          onEntered={() => {
            setIntoPoint(true);
            setDontHideMap(false);
            setTimeout(() => {
              setPointInfoIndex(false);
            }, 1000);
          }}
          onExit={() => {
            if (!showPolicy && !intoPoint) {
              setShowPointsList(true);
            }
            setShowInfoPage(false);
          }}
        >
          {(status: TransitionState) => {
            return (
              <div
                className={`pointInfo pointInfo-${status} bg-dark-blue ${
                  pointInfoIndex && lastPagePointInfo === -1 ? "z-50" : "z-10"
                } overflow-y-hidden`}
                style={{
                  width: windowDimensions.width,
                  minHeight: windowDimensions.height,
                }}
              >
                <PointInfo />
              </div>
            );
          }}
        </Transition>

        <Transition
          in={showMap > 0 && (!landscape || isDesktop)}
          timeout={500}
          appear
          unmountOnExit={true}
          onEnter={() => {
            setShowPointsList(false);
          }}
          onEntered={() => {
            // setShowInfoPage(false);
          }}
          onExit={() => {
            setPointInfoIndex(true);
            if (lastPagePointInfo === -1) {
              setShowPointsList(true);
            }
          }}
        >
          {(status: TransitionState) => {
            return (
              // <div
              //   className={`map ${dontHideMap ? "" : `map-${status}`} bg-dark-blue`}
              //   style={{
              //     width: windowDimensions.width,
              //     minHeight: windowDimensions.height,
              //   }}
              // >
              <div
                className={`map ${
                  dontHideMap ? "" : `map-${status}`
                } bg-dark-blue`}
                style={{
                  width: windowDimensions.width,
                  minHeight: windowDimensions.height,
                }}
              >
                <Map />
              </div>
            );
          }}
        </Transition>

        <Transition
          in={showSurvey && (!landscape || isDesktop)}
          timeout={500}
          appear
          unmountOnExit={true}
          onEnter={() => setShowPointsList(false)}
          onExit={() => {
            setShowPointsList(true);
          }}
        >
          {(status: TransitionState) => {
            return (
              <div
                className={`survey survey-${status} bg-hero-pattern bg-no-repeat bg-cover bg-main-position`}
                style={{
                  width: windowDimensions.width,
                  minHeight: windowDimensions.height,
                }}
              >
                <Survey />
              </div>
            );
          }}
        </Transition>

        {/* {showSurvey ? <Survey /> : null} */}

        <Transition
          in={showLanguagesSelector && (!landscape || isDesktop)}
          timeout={500}
          appear
          unmountOnExit={true}
          onEnter={() => setShowPointsList(false)}
          onExit={() => {
            setShowPointsList(true);
          }}
        >
          {(status) => {
            return (
              <div className={`languagesSelector languagesSelector-${status}`}>
                <ChangeLanguage
                  closeWindow={() => setShowLanguagesSelector(false)}
                />
              </div>
            );
          }}
        </Transition>

        {!showLanguages && (!landscape || isDesktop) && <Footer />}
        {/*Cookies Modal */}

        {showPolicy > 0 && (!landscape || isDesktop) && <Policy />}
      </SnackbarProvider>

      {isMobile && landscape && (
        <div
          className="fixed w-full h-full inset-0 flex flex-col justify-center items-center text-white overflow-hidden"
          style={{ zIndex: 9999, backgroundColor: "#0900b9" }}
        >
          <img className="h-40 transform" src={landscapeImg} alt="Landscape" />
          <h2 className="mt-6 text-2xl text-blue-ted px-12 text-center">
            {generalTexts !== undefined
              ? generalTexts.girar_movil
              : "Turn the mobile to continue with the experience"}
          </h2>
        </div>
      )}
    </div>
  );
};

export default MainElements;
