export const isTablet = () => {
  return navigator.maxTouchPoints > 0 && window.screen.availWidth > 600;
};

function getExtension(filename: string) {
  var parts = filename.split("/");
  return parts[0];
}
export const isVideo = (filename: string) => {
  var ext = getExtension(filename);
  return ext === "video";
};

export const isPc = () => {
  return window.screen.availWidth > 1024;
};

export interface IWindowDimensions {
  width: number;
  height: number;
}

export const getWindowDimensions = (): IWindowDimensions => {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
};

export const getWindowScroll = (): number => {
  return window.scrollY;
};

export function on(obj: any, ...args: any) {
  obj.addEventListener(...args);
}

export function off(obj: any, ...args: any) {
  obj.removeEventListener(...args);
}
