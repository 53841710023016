import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import CommonStore from "../stores/common";
import logo from "../assets/images/icons/TG_landsc.svg";
import { isTablet } from "./../utilities/utils";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import ProjectConfiguration from "../configuration/project";
import Cookies from "js-cookie";
import { useCookiesContext } from "../context/CookiesCtx";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const Survey = () => {
  const { trackPageView, trackEvent } = useMatomo();
  const currentSurvey = CommonStore((state) => state.currentSurvey);
  const currentSurveyNot = CommonStore((state) => state.currentSurveyNot);
  const setShowSurvey = CommonStore((state) => state.setShowSurvey);
  const setSurveyDone = CommonStore((state) => state.setSurveyDone);
  const setSurveyCompletedNot = CommonStore(
    (state) => state.setSurveyCompletedNot
  );
  const surveyCompletedNot = CommonStore((state) => state.surveyCompletedNot);
  const generalTexts = CommonStore((state) => state.generalTexts);
  const { cookies, preferenceCookies } = useCookiesContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  var starRatingNum =
    currentSurvey !== undefined ? currentSurvey.star_rating.length : 0;
  var selectorRatingNum =
    currentSurvey !== undefined
      ? currentSurvey.option_selector_rating.length
      : 0;

  var numQuestions = starRatingNum + selectorRatingNum;
  const [rateList, setRateList] = useState(Array(numQuestions).fill(-1));
  const [arrayChange, setArrayChange] = useState(true);

  function questionsAnswered() {
    const count = rateList.filter((element) => {
      if (element > -1) {
        return true;
      }

      return false;
    }).length;

    return count;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {}, [arrayChange]);
  useEffect(() => {
    if (surveyCompletedNot) {
      enqueueSnackbar("surveySent");
      trackPageView({
        documentTitle: "Página Lista Puntos de Exposición", // optional
      });
    }
  }, [surveyCompletedNot]);

  function sendAnalytics() {
    rateList.map((element, i) => {
      if (i < starRatingNum) {
        // trackEvent({
        //   category: ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.SURVEY,
        //   action:
        //     currentSurvey !== undefined
        //       ? currentSurvey?.star_rating[i].description
        //       : "event",
        //   // name:,
        //   value: rateList[i] + 1,
        // });
        trackEvent({
          category: ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.SURVEY,
          action: "Survey sent - Star rating",
          name: "Pregunta " + (i + 1),
          value: rateList[i] + 1,
        });
      } else {
        // trackEvent({
        //   category: ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.SURVEY,
        //   action:
        //     currentSurvey !== undefined
        //       ? currentSurvey?.option_selector_rating[i - starRatingNum]
        //           .description
        //       : "event",
        //   name:
        //     rateList[i] === 0
        //       ? currentSurvey?.option_selector_rating[i - starRatingNum]
        //           .option_1
        //       : rateList[i] === 1
        //       ? currentSurvey?.option_selector_rating[i - starRatingNum]
        //           .option_2
        //       : currentSurvey?.option_selector_rating[i - starRatingNum]
        //           .option_3,
        // });
        trackEvent({
          category: ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.SURVEY,
          action: "Survey sent - Option selector",
          name: "Pregunta " + (i - starRatingNum + 1),
          value: rateList[i] === 0 ? 1 : rateList[i] === 1 ? 2 : 3,
        });
      }
    });
  }

  useEffect(() => {
    trackPageView({
      documentTitle: "Página Encuesta", // optional
    });
  }, []);

  return (
    <div
      className="absolute top-0 w-full text-white"
      style={{ zIndex: 300, backgroundColor: "#0900b9", minHeight: "100%" }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.4,
        }}
        className="sticky top-0 w-full h-28 flex flex-row content-center"
        style={{ backgroundColor: "#0900b9", zIndex: 10 }}
      >
        <img className="ml-6 mt-0" src={logo} width={180} />
        <div
          onClick={() => {
            trackPageView({
              documentTitle: "Página Lista Puntos de Exposición", // optional
            });
            setShowSurvey(false);
          }}
          className="absolute top-0 right-0 mr-4 mt-4 sm:mr-6 sm:mt-6 cursor-pointer lg:mr-12"
        >
          <CloseIcon />
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.2,
        }}
        className="flex flex-col lg:justify-center lg:items-center"
        style={{ marginTop: "0" }}
      >
        <div className="flex justify-center font-bold mb-12 mt-10 sm:mb-16 sm:mt-12 sm:text-lg">
          {currentSurveyNot?.title}
        </div>
        <div className="flex flex-col justify-center mx-10 sm:mx-52 lg:w-1/4">
          {currentSurvey?.star_rating.map((element, indexQuestion) => (
            <div className="flex flex-col justify-center mb-8">
              <div className="flex mb-4">
                {1 + indexQuestion + ". " + element.description}
              </div>
              <div className={`flex justify-center cursor-pointer`}>
                {[...Array(5)].map((e, indexStar) => (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    viewBox="0 0 248.294 248.294"
                    width="2.5em"
                    height="2.5em"
                    fill={"white"}
                    opacity={rateList[indexQuestion] >= indexStar ? "1" : "0.3"}
                    className="mx-2"
                    onClick={() => {
                      if (rateList[indexQuestion] !== indexStar) {
                        rateList[indexQuestion] = indexStar;
                        setRateList(rateList);
                        setArrayChange(!arrayChange);
                      }
                    }}
                  >
                    <path d="M55.688 242.322c2.882 0 6.069-.719 9.439-2.24l59.032-32.156 59.032 32.156c3.369 1.521 6.557 2.24 9.437 2.24 8.933 0 14.963-6.917 14.543-18.36l-7.71-65.312 44.062-45.268c9.166-12.062 4.732-25.004-9.908-28.908l-65.53-10.529-28.932-58.22c-4.242-6.49-9.959-9.754-15.732-9.754-5.512 0-11.063 2.973-15.422 8.952L74.461 73.941l-59.893 10.06C.002 88.164-4.375 101.315 4.791 113.378l44.06 45.264-7.71 65.311c-.42 11.452 5.612 18.369 14.547 18.369zM20.734 102.347l56.896-9.558 8.961-1.505 4.492-7.906 32.191-56.649 27.689 55.713 4.378 8.809 9.712 1.557 62.101 9.98-41.388 42.515-6.353 6.534 1.064 9.045 7.057 59.795-54.231-29.548-9.145-4.979-9.147 4.979-54.227 29.548 7.052-59.795 1.066-9.045-6.352-6.534-41.816-42.956z" />
                  </svg>
                ))}
              </div>
            </div>
          ))}
          {currentSurvey?.option_selector_rating.map(
            (element, indexQuestion) => (
              <div className="flex flex-col justify-start items-start mb-6">
                <div className="flex mb-4">
                  {1 +
                    starRatingNum +
                    indexQuestion +
                    ". " +
                    element.description}
                </div>
                <div className="flex flex-col justify-center mb-4 w-full gap-y-4">
                  {[...Array(3)].map((e, indexOption) => (
                    <button
                      className="encuestaButton"
                      style={{
                        backgroundColor: "#0900b9",
                        opacity: `${
                          rateList[starRatingNum + indexQuestion] == indexOption
                            ? "1"
                            : "0.3"
                        }`,
                      }}
                      onClick={() => {
                        if (
                          rateList[starRatingNum + indexQuestion] !==
                          indexOption
                        ) {
                          rateList[starRatingNum + indexQuestion] = indexOption;
                          setRateList(rateList);
                          setArrayChange(!arrayChange);
                          // console.log(
                          //   currentSurvey.option_selector_rating[indexQuestion]
                          //     .option_selector_rating_id.id
                          // );
                        }
                      }}
                    >
                      {indexOption === 0
                        ? element.option_1
                        : indexOption === 1
                        ? element.option_2
                        : element.option_3}
                    </button>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
        <div
          className="flex justify-center content-center font-bold sm:mb-44 sm:mt-12 mb-32 mt-4 text-xl cursor-pointer"
          style={{
            opacity: questionsAnswered() === numQuestions ? "1" : "0.5",
          }}
          onClick={() => {
            if (questionsAnswered() === numQuestions) {
              if (cookies) Cookies.set("cookie_survey", "true");
              setSurveyCompletedNot(true);
              setShowSurvey(false);
              setSurveyDone(true);
              sendAnalytics();
            } else {
              enqueueSnackbar("surveyFields");
            }
          }}
        >
          <div className="flex justify-center mr-3 uppercase">
            {generalTexts?.send}
          </div>
          <div className="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              enableBackground="new 0 0 512.002 512.002"
              version="1.1"
              viewBox="0 0 512.002 512.002"
              xmlSpace="preserve"
              width="20"
            >
              <path
                fill="#fff"
                d="M388.425 241.951L151.609 5.79c-7.759-7.733-20.321-7.72-28.067.04-7.74 7.759-7.72 20.328.04 28.067l222.72 222.105-222.728 222.104c-7.759 7.74-7.779 20.301-.04 28.061a19.8 19.8 0 0014.057 5.835 19.79 19.79 0 0014.017-5.795l236.817-236.155c3.737-3.718 5.834-8.778 5.834-14.05s-2.103-10.326-5.834-14.051z"
              ></path>
            </svg>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Survey;
