import { useState, useEffect } from "react";

import Languages from "./pages/Languages";
import PointsList from "./pages/PointsList";
import PointInfo from "./pages/PointInfo";
import CustomSnackbar from "./components/CustomSnackbar";
import Map from "./pages/Map";
import ChangeLanguage from "./components/ChangeLanguage";
import Cookies from "js-cookie";
import { Transition } from "react-transition-group";
import { SnackbarProvider } from "notistack";
import CommonStore from "./stores/common";
import { IWindowDimensions, getWindowDimensions } from "./utilities/utils";
import "./App.css";
import "./styles/PointsListTransitions.css";
import "./styles/ChangeLanguageTransitions.css";
import Survey from "./pages/Survey";
import { useCookiesContext } from "./context/CookiesCtx";
import landscapeImg from "./assets/images/landscape.svg";
// import ReactGA from "react-ga";
import {
  getGeneralTextsFromCMS,
  getLanguages,
  getLanguagesFromCMS,
  ILang,
} from "./utilities/CmsUtils";

import AppNoMatomo from "./components/AppNoMatomo";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import ProjectConfiguration from "./configuration/project";
import useWindowOrientation from "use-window-orientation";
import { isDesktop, isMobile } from "react-device-detect";
import Footer from "./components/Footer";
import { Policy } from "@material-ui/icons";
import MainElements from "./components/MainElements";
import AppMatomoCookies from "./components/AppMatomoCookies";
import AppMatomoNoCookies from "./components/AppMatomoNoCookies";

interface TransitionStyles {
  entering: object;
  entered: object;
  exiting: object;
  exited: object;
}

type TransitionState = keyof TransitionStyles;

function App() {
  const { cookies, analyticsCookies, preferenceCookies } = useCookiesContext();
  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>(
    getWindowDimensions()
  );

  const setCurrentLang = CommonStore((state: any) => state.setCurrentLang);
  const setShowPointsList = CommonStore(
    (state: any) => state.setShowPointsList
  );
  const pointSelected = CommonStore((state: any) => state.pointSelected);

  useEffect(() => {
    if (
      Cookies.get("cookie_language") !== undefined &&
      Cookies.get("cookie_language") !== ""
    ) {
      getLanguagesFromCMS().then(() => {
        const lang = getLanguages()?.filter(
          (element) => element.code === Cookies.get("cookie_language")
        )[0];
        if (lang !== undefined) {
          setCurrentLang(lang);
          setShowPointsList(true);
        }
      });
    }
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Necesario para rerenderizar los audioplayer y que utilicen el audio que les toca
  useEffect(() => {}, [pointSelected]);

  // return cookies && analyticsCookies ? <AppMatomoCookies /> : <AppNoMatomo />;

  return !cookies ? (
    <AppNoMatomo />
  ) : analyticsCookies ? (
    <AppMatomoCookies />
  ) : (
    <AppMatomoNoCookies />
  );
}

export default App;
