import { ContactSupportOutlined } from "@material-ui/icons";
import { GetAsset } from "../directus/assets";
import { GetItem, ListItems, ListItemsByID } from "../directus/items";
import { isEmpty, isNull, isUndefined, StringIterator } from "lodash";
import store from "../stores/common";
import { elementDragControls } from "framer-motion/types/gestures/drag/VisualElementDragControls";
export interface IPointInfo {
  title: string;
  subtitle?: string;
  infoText: string;
}

interface CMSData {
  puntosExpositivos: Map<string, PuntoExpositivoData[]>;
}
export interface PuntoExpositivoData {
  [x: string]: any;
  id: string;
  identifier: string;
  image: string;
  audio: AudioVideo;
  title: string;
  description: string;
  poems: AudioVideo[];
  information: string;
  map_x_position: string;
  map_y_position: string;
  map: string;
  order: number;
  number_name: number;
  videoAudioId: number;
  listPoems: number[];
}

export interface AudioVideo {
  identifier: string;
  title: string;
  file: string;
  blobFile: Blob;
  content_notification: ContentNotification;
}

export interface ContentNotification {
  title: string;
  description: string;
  // image: string;
  time: number;
  url: string;
}

let pointsDataDownloaded: CMSData | undefined = undefined;
pointsDataDownloaded = {
  puntosExpositivos: new Map<string, PuntoExpositivoData[]>(),
};

export const getPoints = (language_code: string) => {
  if (pointsDataDownloaded !== undefined) {
    return pointsDataDownloaded.puntosExpositivos.get(language_code);
  } else {
    return undefined;
  }
};

export async function getPointsFromCMS(language: string) {
  return new Promise(async (resolve, reject) => {
    //Si ya se habia usado este idioma no se vuelve a descargar la informacion de los puntos, ya la tenemos guardada
    if (pointsDataDownloaded?.puntosExpositivos.get(language) == undefined) {
      try {
        // RECORREMOS LOS PUNTOS DEL IDIOMA SELECCIONADO
        await ListItems({
          collection: "point_of_exposition_translations",
          fields: "*",
          filter: `[languages_code][_eq]=${language}`,
        })
          .then(async (traductionsList: any[]) => {
            for (let traduction of traductionsList) {
              // Creamos un nuevo punto de exposicion
              var new_point: PuntoExpositivoData = {
                id: "",
                identifier: "",
                image: "",
                audio: {
                  identifier: "",
                  title: "",
                  blobFile: new Blob(),
                  file: "",
                  content_notification: {
                    title: "",
                    description: "",
                    // image: "",
                    time: -1,
                    url: "",
                  },
                },
                poems: [],
                title: "",
                description: "",
                information: "",
                map_x_position: "",
                map_y_position: "",
                number_name: -1,
                map: "",
                order: -1,
                videoAudioId: 0,
                listPoems: [],
              };

              //Datos que ya tenemos en point_of_exposition_translations (datos que cambian con el idioma)
              new_point.title = traduction.title;
              new_point.description = traduction.description;
              new_point.information = traduction.information;

              // Buscamos los datos con el id del punto de exposicion (datos del punto que no cambian con el idioma)
              await ListItemsByID({
                collection: "point_of_exposition",
                id: traduction.point_of_exposition_id,
                // eslint-disable-next-line no-loop-func
              }).then(async (point_exposition: any) => {
                // Rellenamos los campos obtenidos a partir del id del punto y los de la traduccion
                new_point.identifier = point_exposition.identifier;
                new_point.id = point_exposition.id;
                new_point.map_x_position =
                  point_exposition.map_x_position.toString();
                new_point.map_y_position =
                  point_exposition.map_y_position.toString();
                new_point.number_name = point_exposition.number_name;
                new_point.map = point_exposition.map_selector;
                new_point.order = point_exposition.order;
                new_point.videoAudioId = point_exposition.audio;
                new_point.listPoems = point_exposition.poems;
                // OBTENCION DE LA IMAGEN
                new_point.image = URL.createObjectURL(
                  await GetAsset({
                    fileID: point_exposition.image,
                  })
                );

                // Si el map ya tiene una entrada con ese codigo de lenguaje, se concatena el nuevo punto
                if (
                  pointsDataDownloaded?.puntosExpositivos.has(
                    traduction.languages_code
                  )
                ) {
                  pointsDataDownloaded.puntosExpositivos.set(
                    traduction.languages_code,
                    pointsDataDownloaded.puntosExpositivos
                      .get(traduction.languages_code)!
                      .concat(new_point)
                  );
                }
                // Si el map no tiene la entrada, se crea
                else {
                  pointsDataDownloaded?.puntosExpositivos.set(
                    traduction.languages_code,
                    [new_point]
                  );
                }

                var sorted =
                  pointsDataDownloaded?.puntosExpositivos.get(language);

                if (sorted !== undefined) {
                  sorted = sorted.sort(function (
                    e1: PuntoExpositivoData,
                    e2: PuntoExpositivoData
                  ) {
                    return e1.order - e2.order;
                  });

                  pointsDataDownloaded?.puntosExpositivos.set(language, sorted);
                }
              });
            }
          })
          .finally(async () => {
            // setTimeout(() => {
            //   console.log("CHECK");
            //   loadAudioVideo(language);
            //   loadPoems(language);
            // }, 5000);
            loadAudioVideo(language);
            loadPoems(language);
            resolve(true);
          })
          .catch(() => {
            console.log("catch");
            reject(false);
          });

        // resolve(true);
      } catch (error) {
        console.error("Error CMS: ", error);
        // reject(error);
      }
    } else {
      resolve(true);
    }
  });
}

export interface ILang {
  code: string;
  name: string;
  short_name: string;
  order: number;
}

let languageDataDownloaded: ILang[] | undefined = undefined;

export const getLanguages = () => {
  if (languageDataDownloaded !== undefined) {
    return languageDataDownloaded;
  } else {
    return undefined;
  }
};

export async function getLanguagesFromCMS() {
  return new Promise(async (resolve, reject) => {
    try {
      // Recorremos las traducciones de los puntos de exposición
      await ListItems({
        collection: "languages",
        order: "order",
      })
        .then(async (languages: ILang[]) => {
          languageDataDownloaded = languages;
        })
        .finally(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("catch");
          reject(false);
        });

      // resolve(true);
    } catch (error) {
      console.error("Error CMS: ", error);
      // reject(error);
    }
  });
}

interface pointID {
  point_of_exposition_id: string;
}

export interface SurveyNotification {
  title: string;
  description: string;
  sent_survey_message: string;
  not_now_button_message: string;
  go_to_survey_button_message: string;
  point_of_exposition_time: number;
  point_of_exposition: pointID[];
}

let surveyNotDataDownloaded: SurveyNotification | undefined = undefined;

export const getSurveyNot = () => {
  if (surveyNotDataDownloaded !== undefined) {
    return surveyNotDataDownloaded;
  } else {
    return undefined;
  }
};

export async function getSurveyNotFromCMS(language: string) {
  return new Promise(async (resolve, reject) => {
    try {
      // Recorremos las traducciones de los puntos de exposición
      await ListItems({
        collection: "survey_notification_translations",
        fields: "*",
        filter: `[languages_code][_eq]=${language}`,
      })
        .then(async (survey_notification: SurveyNotification[]) => {
          await GetItem({
            collection: "survey_notification",
            id: "1",
            fields:
              "point_of_exposition.point_of_exposition_id, point_of_exposition_time",
          }).then(async (test: any) => {
            surveyNotDataDownloaded = {
              title: survey_notification[0].title,
              description: survey_notification[0].description,
              sent_survey_message: survey_notification[0].sent_survey_message,
              not_now_button_message:
                survey_notification[0].not_now_button_message,
              go_to_survey_button_message:
                survey_notification[0].go_to_survey_button_message,
              point_of_exposition_time: test.point_of_exposition_time,
              point_of_exposition: test.point_of_exposition,
            };
          });
        })
        .finally(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("catch");
          reject(false);
        });

      // resolve(true);
    } catch (error) {
      console.error("Error CMS: ", error);
      // reject(error);
    }
  });
}

export interface StarRating {
  description: string;
  star_rating_id: {
    visibility: boolean;
    id: number;
  };
}

export interface OptionSelectorRating {
  description: string;
  option_1: string;
  option_2: string;
  option_3: string;
  option_selector_rating_id: {
    visibility: boolean;
    id: number;
  };
}

export interface Survey {
  star_rating: StarRating[];
  option_selector_rating: OptionSelectorRating[];
}

let surveyDataDownloaded: Survey | undefined = undefined;

export const getSurvey = () => {
  if (surveyDataDownloaded !== undefined) {
    return surveyDataDownloaded;
  } else {
    return undefined;
  }
};

export async function getSurveyFromCMS(language: string) {
  return new Promise(async (resolve, reject) => {
    var starRating: StarRating[];

    try {
      // Recorremos las traducciones de los puntos de exposición
      await ListItems({
        collection: "star_rating_translations",
        fields: "*,star_rating_id.visibility,star_rating_id.id",
        filter: `[languages_code][_eq]=${language}`,
      })
        .then(async (star_rating: StarRating[]) => {
          starRating = star_rating;

          await ListItems({
            collection: "option_selector_rating_translations",
            fields:
              "*,option_selector_rating_id.visibility, option_selector_rating_id.id",
            filter: `[languages_code][_eq]=${language}`,
          })
            .then(async (option_selector_rating: OptionSelectorRating[]) => {
              //Eliminamos las preguntas que no tienen la visibilidad activada
              const visibleStarRating = starRating.filter((element) => {
                return element.star_rating_id.visibility === true;
              });
              surveyDataDownloaded = {
                star_rating: visibleStarRating,
                option_selector_rating: option_selector_rating,
              };

              const visibleOptionSelectorRating = option_selector_rating.filter(
                (element) => {
                  return element.option_selector_rating_id.visibility === true;
                }
              );
              surveyDataDownloaded = {
                star_rating: visibleStarRating,
                option_selector_rating: visibleOptionSelectorRating,
              };
            })
            .finally(() => {
              resolve(true);
            })
            .catch(() => {
              console.log("catch");
              reject(false);
            });
        })
        .finally(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("catch");
          reject(false);
        });

      // resolve(true);
    } catch (error) {
      console.error("Error CMS: ", error);
      // reject(error);
    }
  });
}

export interface GeneralTexts {
  cookies_policy: string;
  privacy_policy: string;
  read_more: string;
  read_less: string;
  poems: string;
  dome: string;
  first_floor: string;
  send: string;
  cookies_policy_text: string;
  privacy_policy_text: string;
  information_about_cookies: string;
  advanced_configuration_cookies: string;
  accept_and_close_cookies: string;
  description_cookies: string;
  required_cookies: string;
  description_required_cookies: string;
  always_enabled_cookies: string;
  analytical_cookies: string;
  description_analytical_cookies: string;
  preference_cookies: string;
  enabled_cookies: string;
  disabled_cookies: string;
  description_preference_cookies: string;
  save_configuration_cookies: string;
  pkid_description: string;
  pkses_description: string;
  sesid_description: string;
  cookie_language_description: string;
  cookie_survey_description: string;
  duration: string;
  description: string;
  day: string;
  minute: string;
  year: string;
  month: string;
  survey_error: string;
  girar_movil: string;
}

let generalTextsDataDownloaded: GeneralTexts | undefined = undefined;

export const getGeneralTexts = () => {
  if (generalTextsDataDownloaded !== undefined) {
    return generalTextsDataDownloaded;
  } else {
    return undefined;
  }
};

export async function getGeneralTextsFromCMS(language: string) {
  return new Promise(async (resolve, reject) => {
    try {
      // Recorremos las traducciones de los puntos de exposición
      await ListItems({
        collection: "general_texts_translations",
        fields: "*",
        filter: `[languages_code][_eq]=${language}`,
      })
        .then(async (general_texts: GeneralTexts[]) => {
          generalTextsDataDownloaded = general_texts[0];
        })
        .finally(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("catch");
          reject(false);
        });

      // resolve(true);
    } catch (error) {
      console.error("Error CMS: ", error);
      // reject(error);
    }
  });
}

async function loadPoems(language: string) {
  const pointsLanguage = pointsDataDownloaded!.puntosExpositivos.get(language);
  let arrayPoems: AudioVideo[];
  let index = 0;
  for (const point of pointsLanguage!) {
    arrayPoems = [];
    if (point.listPoems.length > 0) {
      for (const poem of point.listPoems) {
        const items = await ListItemsByID({
          collection: "point_of_exposition_videos_and_audios_1",
          id: poem.toString(),
        });

        const listItems = await ListItems({
          collection: "videos_and_audios_translations",
          fields: "*",
          filter: `[videos_and_audios_id][_eq]=${items.videos_and_audios_id}`,
          filter2: `[languages_id][_eq]=${language}`,
        });

        const item = await GetItem({
          collection: "videos_and_audios",
          id: items.videos_and_audios_id,
          fields:
            "identifier,content_notification.*,content_notification.translations.*",
        });

        let saveContentNotification = {
          title: "",
          description: "",
          url: "",
          image: "",
          translations: [
            {
              title: "",
              description: "",
              languages_code: "",
            },
          ],
          time: -1,
        };

        if (item.content_notification !== null) {
          saveContentNotification = item.content_notification;
        }

        const asset = await GetAsset({
          fileID: listItems[0].file,
        });

        var translation;
        var title = "";
        var description = "";
        translation = saveContentNotification.translations.find(
          (element) => element.languages_code === language
        );
        if (!isUndefined(translation)) {
          title = translation.title;
          description = translation.description;
        }

        arrayPoems.push({
          identifier: item.identifier,
          title: listItems[0].title,
          blobFile: asset,
          file: URL.createObjectURL(asset),
          content_notification: {
            time: saveContentNotification.time,
            url: saveContentNotification.url,
            title: title,
            description: description,
          },
        });
      }
    }

    pointsLanguage![index].poems = arrayPoems;
    if (
      store.getState().pointSelected &&
      pointsLanguage![index].id === store.getState().pointSelected.id
    ) {
      store.setState((prevState) => ({
        pointSelected: {
          ...prevState.pointSelected,
          poems: arrayPoems,
        },
      }));
    }
    index++;
  }
  //console.log("POEMS LOADED");
}

async function loadAudioVideo(language: string) {
  const pointsLanguage = pointsDataDownloaded!.puntosExpositivos.get(language);

  let i = 0;
  for (const element of pointsLanguage!) {
    const info = await ListItems({
      collection: "videos_and_audios_translations",
      fields: "*",
      filter: `[languages_id][_eq]=${language}`,
      filter2: `[videos_and_audios_id][_eq]=${element.videoAudioId}`,
    });

    if (info[0]) {
      const asset = await GetAsset({ fileID: info[0].file });
      const item = await GetItem({
        collection: "videos_and_audios",
        id: element.videoAudioId.toString(),
        fields:
          "identifier,content_notification.*,content_notification.translations.*",
      });

      let contentNotification = {
        time: -1,
        url: "",
        title: "",
        description: "",
      };

      if (item.content_notification) {
        let translation = item.content_notification.translations.find(
          (element: any) => element.languages_code === language
        );
        contentNotification = translation;
        contentNotification.time = item.content_notification.time;
        contentNotification.url = item.content_notification.url;
      }

      const audio = {
        identifier: item.identifier,
        title: info[0].title,
        file: URL.createObjectURL(asset),
        blobFile: asset,
        content_notification: {
          time: contentNotification.time,
          url: contentNotification.url,
          title: contentNotification.title,
          description: contentNotification.description,
        },
      };

      pointsLanguage![i].audio = audio;

      if (
        store.getState().pointSelected &&
        pointsLanguage![i].id === store.getState().pointSelected.id
      ) {
        store.setState((prevState) => ({
          pointSelected: {
            ...prevState.pointSelected,
            audio: audio,
          },
        }));
      }
    }
    i++;
  }
  //console.log("AUDIOS LOADED");
}
