import ProjectConfiguration from "../configuration/project";
import { AuthenticationResponse, LoginCredentials } from "./types";
import { Login /*, Logout */ } from "./authentication";

/**
 * Items
 *
 *  - Items are individual pieces of data in your database.
 *  - They can be anything, from articles, to IoT status checks. Learn more about Items.
 */

export interface ListItemsNamedParameters {
  loginCredentials?: LoginCredentials | undefined;
  collection: string;
  fields?: string;
  filter?: string;
  filter2?: string;
  order?: string;
}

/**
 * List Items
 *
 *  - List all items that exist in Directus.
 *
 * @param loginCredentials?
 * @param collection
 * @param fields
 */
export async function ListItems({
  loginCredentials,
  collection,
  fields,
  filter,
  filter2,
  order,
}: ListItemsNamedParameters): Promise<any> {
  let items: any = [];

  if (loginCredentials) {
    await Login(loginCredentials)
      .then(async (response: AuthenticationResponse) => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + response.access_token,
          },
        };

        await fetch(
          `${ProjectConfiguration.DIRECTUS_BASE_URL}/items/${collection}?fields=${fields}`,
          requestOptions
        )
          .then(async (response) => {
            const data = await response.json();

            if (!response.ok) {
              // Get Error Message from Body or Default to Response Status
              const error = (data && data.errors) || response.status;
              // Return throwing Error obtained
              return Promise.reject(error);
            }

            items = data.data;
          })
          .catch((error) => {
            console.log(error[0].extensions.code + ": " + error[0].message);
          });

        // if (process.env.NODE_ENV.includes("production")) {
        //   await Logout(response.refresh_token);
        // }
      })
      .catch((error) => {
        console.log(error[0].extensions.code + ": " + error[0].message);
      });
  } else {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    await fetch(
      filter
        ? filter2
          ? `${ProjectConfiguration.DIRECTUS_BASE_URL}/items/${collection}?fields=${fields}&limit=1000&sort=id&filter${filter}&filter${filter2}`
          : `${ProjectConfiguration.DIRECTUS_BASE_URL}/items/${collection}?fields=${fields}&limit=1000&sort=id&filter${filter}`
        : fields
        ? `${ProjectConfiguration.DIRECTUS_BASE_URL}/items/${collection}?fields=${fields}&limit=1000&sort=id`
        : order
        ? `${ProjectConfiguration.DIRECTUS_BASE_URL}/items/${collection}?sort=${order}`
        : `${ProjectConfiguration.DIRECTUS_BASE_URL}/items/${collection}`,
      requestOptions
    )
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          // Get Error Message from Body or Default to Response Status
          const error = (data && data.errors) || response.status;
          // Return throwing Error obtained
          return Promise.reject(error);
        }

        items = data.data;
      })
      .catch((error) => {
        console.log(error[0].extensions.code + ": " + error[0].message);
      });
  }

  return items;
}

/**
 * Items
 *
 *  - Items are individual pieces of data in your database.
 *  - They can be anything, from articles, to IoT status checks. Learn more about Items.
 */

export interface ListItemsByIDNamedParameters {
  loginCredentials?: LoginCredentials | undefined;
  collection: string;
  id: string;
}

/**
 * List Items
 *
 *  - List all items that exist in Directus.
 *
 * @param loginCredentials?
 * @param collection
 *
 */
export async function ListItemsByID({
  loginCredentials,
  collection,
  id,
}: ListItemsByIDNamedParameters): Promise<any> {
  let items: any = [];

  if (loginCredentials) {
    await Login(loginCredentials)
      .then(async (response: AuthenticationResponse) => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + response.access_token,
          },
        };

        await fetch(
          `${ProjectConfiguration.DIRECTUS_BASE_URL}/items/${collection}/${id}`,
          requestOptions
        )
          .then(async (response) => {
            const data = await response.json();

            if (!response.ok) {
              // Get Error Message from Body or Default to Response Status
              const error = (data && data.errors) || response.status;
              // Return throwing Error obtained
              return Promise.reject(error);
            }

            items = data.data;
          })
          .catch((error) => {
            console.log(error[0].extensions.code + ": " + error[0].message);
          });

        // if (process.env.NODE_ENV.includes("production")) {
        //   await Logout(response.refresh_token);
        // }
      })
      .catch((error) => {
        console.log(error[0].extensions.code + ": " + error[0].message);
      });
  } else {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch(
      `${ProjectConfiguration.DIRECTUS_BASE_URL}/items/${collection}/${id}`,
      requestOptions
    )
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          // Get Error Message from Body or Default to Response Status
          const error = (data && data.errors) || response.status;
          // Return throwing Error obtained
          return Promise.reject(error);
        }

        items = data.data;
      })
      .catch((error) => {
        console.log(error[0].extensions.code + ": " + error[0].message);
      });
  }

  return items;
}

export interface GetItemNamedParameters {
  loginCredentials?: LoginCredentials | undefined;
  collection: string;
  id: string;
  fields: string;
}

/**
 * Retrieve an Item
 *
 *  - Get an item that exists in Directus.
 *
 * @param loginCredentials?
 * @param collection
 * @param id
 * @param fields
 */
export async function GetItem({
  loginCredentials,
  collection,
  id,
  fields,
}: GetItemNamedParameters): Promise<any> {
  let item: any = {};

  if (loginCredentials) {
    await Login(loginCredentials)
      .then(async (response: AuthenticationResponse) => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + response.access_token,
          },
        };

        await fetch(
          `${ProjectConfiguration.DIRECTUS_BASE_URL}/items/${collection}/${id}?fields=${fields}`,
          requestOptions
        )
          .then(async (response) => {
            const data = await response.json();

            if (!response.ok) {
              // Get Error Message from Body or Default to Response Status
              const error = (data && data.errors) || response.status;
              // Return throwing Error obtained
              return Promise.reject(error);
            }

            item = data.data;
          })
          .catch((error) => {
            console.log(error[0].extensions.code + ": " + error[0].message);
          });

        // if (process.env.NODE_ENV.includes("production")) {
        //   await Logout(response.refresh_token);
        // }
      })
      .catch((error) => {
        console.log(error[0].extensions.code + ": " + error[0].message);
      });
  } else {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch(
      `${ProjectConfiguration.DIRECTUS_BASE_URL}/items/${collection}/${id}?fields=${fields}`,
      requestOptions
    )
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          // Get Error Message from Body or Default to Response Status
          const error = (data && data.errors) || response.status;
          // Return throwing Error obtained
          return Promise.reject(error);
        }

        item = data.data;
      })
      .catch((error) => {
        console.log(error[0].extensions.code + ": " + error[0].message);
      });
  }

  return item;
}
