import { duration } from "@material-ui/core";
import { ContactSupportOutlined } from "@material-ui/icons";
import { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";
import CommonStore from "../stores/common";

const containerStyles = {
  height: 5,
  backgroundColor: "#e0e0de",
  borderRadius: 50,
};

const fillerStyles = {
  height: 5,
  backgroundColor: "#E5007D",
  borderRadius: "inherit",
  top: "50%",
  transform: "translate(0, -50%)",
};

const buttonStyles = {
  marginTop: 0,
  width: 24,
  height: 24,
};

interface IProgressBar {
  totalDuration: number;
  progress: number;
  seekAudio: (percentaje: number) => void;
}

function secondsToHms(d: number) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h;
  var mDisplay = m < 10 ? "0" + m : m;
  var sDisplay = s < 10 ? "0" + s : s;

  return mDisplay + ":" + sDisplay;
}
const PlayerProgresBar = ({
  seekAudio,
  totalDuration = 0,
  progress,
}: IProgressBar) => {
  const [isDragging, setIsDraggin] = useState<boolean>(false);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const setIsDragging = CommonStore(
    (state) => state.setIsDragging
  );

  useEffect(() => {
    secondsToHms(totalDuration);
  }, []);

  useEffect(() => {
    if (isDragging) return;
    const totalWidth =
      progressBarRef.current!.getBoundingClientRect().width - 24;
    const positionX = (((progress * 100) / totalDuration) * totalWidth) / 100;
    if (positionX === progressBarRef.current!.getBoundingClientRect().width) {
      seekAudio(0);
      return;
    }
    setCurrentProgress(positionX + 4);
  }, [progress]);

  const getWidthPercentaje = (currentWidth: number) => {
    const totalWidth =
      progressBarRef.current!.getBoundingClientRect().width - 24;

    if ((currentWidth * 100) / totalWidth === 100) {
      setTimeout(() => {
        // setCurrentProgress(0);
        seekAudio(0);
      }, 500);

      return;
    }
    seekAudio((currentWidth * 100) / totalWidth);
  };

  

  return (
    <div className="relative" style={{ marginBottom: 15 }}>
      <div
        className="w-full flex items-center"
        style={{
          height: 24,
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <div className="w-full " style={containerStyles}>
          <div
            className="absolute top-0"
            style={{
              ...fillerStyles,
              width: isNaN(currentProgress) ? 0 : currentProgress + 4,
            }}
          ></div>
        </div>
      </div>
      <div
        ref={progressBarRef}
        className="absolute top-0"
        style={{ width: "100%" }}
      >
        {" "}
        <Draggable
          // onStart={(event, data) => console.log("START", data.x)}
          onDrag={(event, data) => {
            setIsDraggin(true);
            setCurrentProgress(data.x);
            getWidthPercentaje(data.x);
            setIsDragging(true)
          }}
          onStop={(event, data) => {
            setIsDraggin(false);
            getWidthPercentaje(data.x);
            setIsDragging(false)
          }}
          position={{ x: currentProgress | 0, y: 0 }}
          axis="x"
          bounds="parent"
        >
          <div
            className="absolute z-50 audioPlayerCircle bg-yellow-300"
            style={buttonStyles}
          ></div>
        </Draggable>
      </div>
      <div className="absolute px-1 text-white text-xs w-full mt-2">
        <span>{secondsToHms(progress)}</span>
        <span className="float-right">
          {isNaN(totalDuration) ? "00:00" : secondsToHms(totalDuration)}
        </span>
      </div>
    </div>
  );
};

export default PlayerProgresBar;
