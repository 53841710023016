import proximo from "../assets/images/icons/proximo.svg";
import Icon from "./SVGs/Charla";
import { motion } from "framer-motion";
import CommonStore from "../stores/common";

interface ActionRectangreProps {
  title: string;
  subtitle?: string;
  numberName: number;
  componentStyles: string;
  titleStyles: string;
  onClickAction: () => void;
}

const ActionRectange = ({
  title,
  subtitle,
  numberName,
  componentStyles,
  titleStyles,
  onClickAction,
}: ActionRectangreProps) => {
  const showMap = CommonStore((state: any) => state.showMap);
  const showLanguagesSelector = CommonStore(
    (state: any) => state.showLanguagesSelector
  );

  return numberName >= 0 ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
        delay: showMap ? 0.1 : 0.3,
      }}
      onClick={onClickAction}
      className={`${componentStyles} flex flex-row justify-center items-center text-white ${
        numberName > 0 ? "my-2" : "mt-5"
      } px-4 cursor-pointer buttonBox`}
      style={{
        backgroundColor: numberName > 0 ? "#0029de" : "#090083",
        color: numberName > 0 ? "white" : "#e5007d",
        // backgroundColor: numberName > 0 ? "#090083" : "#0029de",
        // color: numberName > 0 ? "#e5007d" : "white",
      }}
    >
      <div className={`${titleStyles} flex flex-col align-center`}>
        <div className="flex flex-row justify-center items-center">
          {numberName > 0 && (
            <div className="mr-6 text-3xl" style={{ color: "#e5007d" }}>
              {numberName.toString().padStart(2, "0")}
            </div>
          )}
          <div
            className={`${titleStyles} ${
              numberName > 0 ? "font-normal" : "font-bold"
            } `}
          >
            {title}
          </div>
        </div>

        {/* <small className="text-base font-light">{subtitle}</small> */}
      </div>
      <div className="ml-auto flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          enableBackground="new 0 0 512.002 512.002"
          version="1.1"
          viewBox="0 0 512.002 512.002"
          xmlSpace="preserve"
          width="24"
        >
          <path
            fill={numberName > 0 ? "white" : "#e5007d"}
            // fill={numberName > 0 ? "#e5007d" : "white"}
            d="M388.425 241.951L151.609 5.79c-7.759-7.733-20.321-7.72-28.067.04-7.74 7.759-7.72 20.328.04 28.067l222.72 222.105-222.728 222.104c-7.759 7.74-7.779 20.301-.04 28.061a19.8 19.8 0 0014.057 5.835 19.79 19.79 0 0014.017-5.795l236.817-236.155c3.737-3.718 5.834-8.778 5.834-14.05s-2.103-10.326-5.834-14.051z"
          ></path>
        </svg>
      </div>
    </motion.div>
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
        delay: showMap ? 0.1 : 0.3,
      }}
      onClick={onClickAction}
      className={`${componentStyles} flex flex-row justify-center items-cente text-white my-5 px-4 cursor-pointer buttonBox`}
      style={{
        backgroundColor: "#0029de",
      }}
    >
      <div className={`${titleStyles} flex flex-col align-center`}>
        <span className={`${titleStyles} font-medium`}>{title}</span>
        {/* <small className="text-base font-light">{subtitle}</small> */}
      </div>
      <div className="ml-auto flex items-center">
        <Icon />
      </div>
    </motion.div>
  );
};

export default ActionRectange;
